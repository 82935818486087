.divGlobal {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 0;
  .Breadcrumb {
    margin-bottom: 10px;
  }
}
.Profile {
  display: flex;
  flex-direction: row !important;
  .ProfileData {
    width: "50%";
    padding: 0 1.5rem;
  .ProfileSecurity {
    width: "60%";
    padding: 0px 3%;

  }
}
@media (max-width: 768px) {

  .ProfileData,
  .ProfileSecurity {
    padding: 0;
  }
}

  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column !important;
    .ProfileData {
      width: 100%;
      margin-right: 3%;
    }
    .ProfileSecurity {
      width: 100%;
      margin-bottom: 2rem;
    }
  }
}
.Profile::-webkit-scrollbar {
  width: 4px !important;
  height: 4px;
}
.Profile::-webkit-scrollbar-track {
  background-color: var(--card_color) !important;
  z-index: 999;
  border: none !important;
  box-shadow: none !important;
}
.Profile::-webkit-scrollbar-thumb {
  background-color: var(--icon-color); /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
}

.Upload {
  width: 100%;
  display: flex;
  margin: auto !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.iconUpload {
  font-size: 20px !important;
  margin-right: 5px !important;
}
.Btn_upload {
  margin: 15px;
  margin-top: 15px;
}
.btnUpload {
  margin-top: 30px !important;
  display: flex !important;
  color: #000000 !important;
  opacity: 65% !important; 
  border: 1px solid rgba(0, 0, 0, 0.2) !important;
  border-radius: 5px !important;
  padding:0.2rem 1rem 0.2rem 1rem !important;
}
.ProfileForm {
  width: 100%;
}
.generalinfos {
  color: rgba(0,0,0,.85);
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 15px;
  margin-top: 20px;
}
.InputDiv {
  @media screen and (max-width: 767px) {
    width: 100%;
    }
    @media screen and (max-width: 767px) {
      width: 96%;
    }
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  .formItem1 {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-right: 3%;
    label {
      font-size: 16px;
      font-weight: 400;
      color: black !important;
      .requiredLabel {
        color: #f90001;
      }
    }
    .ant-col-8 {
      max-width: 100% !important;
    }
    @media screen and (max-width: 767px) {
      width: 100%;
      margin-right: 0%;
    }
  }

  .formItem2 {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    label {
      font-size: 14px;
      font-weight: 400;
      color: RGBA(0, 0, 0, 0.85);
      .requiredLabel {
        color: #f90001;
      }
    }
    .ant-col-8 {
      max-width: 100% !important;
    }
    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }
  .Input{
    width: 100%;
    display: inline-block;
    font-weight: bold;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 10px;
    @media screen and (max-width: 767px) {
      width: 100%;
      display: inline-block;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      font-weight: bold;
      margin-top: 10px;
    }
  }
  .InputSecurity{
    width: 100%;
    display: inline-block;
    font-weight: bold;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 30px;
    margin-left: 20px;
    @media screen and (max-width: 767px) {
      width: 100%;
      display: inline-block;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      font-weight: bold;
      margin-top: 30px;
    }
  }
  .InputSelect{
    width: "100%";
    display: inline-block;
    font-weight: bold;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 20px;
    margin-left: 20px;
    @media screen and (max-width: 767px) {
      width: "100%";
      display: inline-block;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      font-weight: bold;
      margin-top: 20px;
      margin-left: 20px;
    }
  }  
  .InputLablDiv {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 0px !important;
    gap: 1rem;
    @media screen and (max-width: 767px) {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
    }
  }
  .InputLablDivSecurity {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 10px !important;
    @media screen and (max-width: 767px) {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
    }
  }


  .formItem3 {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    label {
      font-size: 14px;
      font-weight: 400;
      color: RGBA(0, 0, 0, 0.85);
    }
    .ant-col-8 {
      max-width: 100% !important;
    }
    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }
  .ant-col-22 {
    max-width: 100% !important;
  }
}
.ant-form-item-label {
  text-align: left !important;
  width: 100% !important;
}

.submitcancelDiv {
  width: 96%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 20px;
  .bntCancel {
    margin-right: 5px;
  }
  .btnSubmit {
    margin-left: 5px;
  }
}

.submit_div {
  width: 100%;
  background-color: RGBA(0, 0, 0, 0.04);
  height: 58px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 2%;
  .profile_title {
    font-size: 20px;
    font-weight: 500;
    color: RGBA(0, 0, 0, 0.85);
  }
  .btnCancel {
    margin-right: 10px;
  }
}
.ant-form-item-label
  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  content: none;
  //float: left;
}

.ant-form-item-label > label::after {
  content: none;
  //float: left;
}

.required {
  display: inline-block;
  margin-left: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: "*";
}
.menuItem {
  padding: 5px !important;
  margin-left: 5px !important;
}
.MDP {
  color: black !important;
  font-size: 14px !important;
}

.personIcon{
  font-size: 100px !important;
}



.css-uhb5lp{
  max-width: calc(100% - 64px) !important;
}

.InputLablDiv fieldset {
  border: 1px solid rgb(224, 224, 224);
}
.InputLablDivSecurity fieldset {
  border: 1px solid rgb(224, 224, 224);
}
.userUploadIcon{
  height: 15px !important;
  width: 20px !important;
  margin-right: 0.5rem;
}
.userUploadImage{
  height: 110px;
  width: 180px;
}