.rect {
  width: 100% !important;
  .rect_filter {
    width: 100% !important;
  }
}


.loading-container {
  height: 80vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.notFoundContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.divCardGroup {
  margin-left: 2% !important;
width: 96% !important;
display: flex;
flex-direction: column;
position: relative;
gap: 1rem;
  .ant-card-bordered {
    height: 85vh;
    width: 100%;
    @media screen and (max-width: 767px) {
      width: 100%;
    }
    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }

  legend {
    width: auto;
  }
 
  .formItem1 {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    label {
      font-size: 14px;
      font-weight: 400;
      color: RGBA(0, 0, 0, 0.85);

      .requiredLabel {
        color: #f90001;
      }
    }

    .ant-col-8 {
      max-width: 100% !important;
    }

    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }

  .formItem2 {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    label {
      font-size: 14px;
      font-weight: 400;
      color: RGBA(0, 0, 0, 0.85);

      .requiredLabel {
        color: #f90001;
      }
    }

    .ant-col-8 {
      max-width: 100% !important;
    }

    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }

  .formItem3 {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    label {
      font-size: 14px;
      font-weight: 400;
      color: RGBA(0, 0, 0, 0.85);

      .requiredLabel {
        color: #f90001;
      }
    }

    .ant-col-8 {
      max-width: 100% !important;
    }

    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }

  .ant-col-22 {
    max-width: 100% !important;
  }

  .InputLablDiv {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 10px;
    @media screen and (max-width: 767px) {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      margin-top: 10px;
    }
  }

  .ant-form-item-label {
    text-align: left !important;
    width: 100% !important;
  }
}
.searchh{
  padding: 2px 4px !important;
    display: flex !important;
    // align-items: center !important;
        box-shadow: none !important;
        margin-left: 1% !important;
        width: 98% !important;
        margin-right: 5% !important;
        background-color:#F0F0F0 !important;
          opacity: 30% !important;
         border : none !important;
  }
  .icon_action{
    font-size:1.3rem !important
  }


  .modal-group-details .css-tlc64q-MuiPaper-root-MuiDialog-paper {
    border-radius: unset !important;
  }
  
  .modal-group-details h2 {
    color: #FFF;
    font-size: 1.25rem !important;
    line-height: 1.6 !important;
    letter-spacing: 0.0075em !important;
    font-family: system-ui !important;
  }
  
  .group-details-modal{
    width: 100%;
    display: flex;
    flex-direction: column;
    .group-details-modal-container{
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 10px;
      padding-bottom: 1%;
      .group-details-modal-title{
        font-size: 14px;
        font-weight: 500;
        font-style: normal;
      }
      .group-details-modal-value{
        font-size: 14px;
        font-weight: 400;
        border-radius: 6px;
        height: auto;
        padding: 11px;
        border: 1px solid #e0e0e0;
        color: RGBA(0, 0, 0, 0.85);
      }
      .group-details-modal-valuemore{
        font-size: 14px;
        font-weight: 400;
        border-radius: 6px;
        height: auto;
        padding: 11px;
        border: 1px solid #e0e0e0;
        color: RGBA(0, 0, 0, 0.85);
        height: 100px;
      }
    }
  }
  .actionGroup{
    display: flex !important;
    flex-direction: row !important;
    width: 130px ;
    align-items: center !important;
    justify-content: space-between;
    border-radius: 4px;
    div{
      font-size: 15px !important;
      .icon_action{
        cursor: pointer !important;
  
      }
    }
    @media screen and (max-width: 768px) {
      display: flex !important;
      flex-direction: column !important;
      justify-content: space-between !important;
    }
  }
  .Filter .delete_number{
    width: 40px !important;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-inline: 1rem;
  }

  .css-hlj6pa-MuiDialogActions-root {
    gap: 1em;
    margin-right: 1em;
}
.header_container_div{
  margin-bottom: 1rem;
}
.select-again{
  margin-top: -16px;
}

.css-wk780c-MuiSvgIcon-root{
  margin-left: 0 !important;
}

.divCardGroup > .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root{
  margin-top: 0 !important;
}
.divCardGroup > .MuiBox-root > .css-q8hpuo-MuiFormControl-root > .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root{
  margin-top: 0 !important;
}
.DataTable{
  height: 100% !important;
  white-space: nowrap!important;
}

.FilterGroup{
  display: flex;
  flex-direction: row !important;
  align-items: center;
  box-shadow: 0px 4px 10px 2px rgba(0, 0, 0, 0.3);
margin-top: 1rem;
border-radius: 4px;
width: 100%;
}


.generalinfosGroup{
  color: rgba(0,0,0,.65);
font-size: 20px;
font-weight: 700;
margin-bottom: 15px;
margin-top: 15px;
}

.divCardGroup fieldset {
  border: 1px solid rgb(224, 224, 224);
}
.divCardGroup label {
  margin-bottom: 0.5rem;
}

.rowCorbeille{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.rowCorbeille .delete_number {
  margin-right: 0.5rem;
  margin-top: 1rem;
}