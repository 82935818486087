.detailsUserDiv_spn2{
  text-align: right;
}

///////////// add Edit Form /////////////
.divCard {
margin-left: 2% !important;
width: 96% !important;
display: flex;
flex-direction: column;
position: relative;
gap: 1rem;

    @media screen and (max-width: 767px) {
      width: 100%;
    }
    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }
  .modal-ip-details .css-tlc64q-MuiPaper-root-MuiDialog-paper {
    border-radius: unset !important;
  }
  
  .modal-ip-details h2 {
    color: #FFF;
    font-size: 1.25rem !important;
    line-height: 1.6 !important;
    letter-spacing: 0.0075em !important;
    font-family: system-ui !important;
  }
  
  .ip-details-modal{
    width: 100%;
    display: flex;
    flex-direction: column;
    .ip-details-modal-container{
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 10px;
      padding-bottom: 1%;
      .ip-details-modal-title{
        font-size: 14px;
        font-weight: 500;
        font-style: normal;
      }
      .ip-details-modal-value{
        font-size: 14px;
        font-weight: 400;
        border-radius: 6px;
        height: auto;
        padding: 11px;
        border: 1px solid #e0e0e0;
        color: RGBA(0, 0, 0, 0.85);
      }
      .ip-details-modal-valuemore{
        height: 100px;
      }
    }
  }
.divGlobalTable{
  height: 97vh !important;
  padding: 10px 10px 0px 10px;
  margin-bottom: 0 !important;
  position: relative;
}
  .formItem1 {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    label {
      font-size: 14px;
      font-weight: 400;
      color: RGBA(0, 0, 0, 0.85);

      .requiredLabel {
        color: #F90001;
      }
    }

    .ant-col-8 {
      max-width: 100% !important;
    }

    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }

  .formItem2 {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    label {
      font-size: 14px;
      font-weight: 400;
      color: RGBA(0, 0, 0, 0.85);

      .requiredLabel {
        color: #F90001;
      }
    }

    .ant-col-8 {
      max-width: 100% !important;
    }

    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }

  .formItem3 {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    label {
      font-size: 14px;
      font-weight: 400;
      color: RGBA(0, 0, 0, 0.85);

      .requiredLabel {
        color: #F90001;
      }
    }

    .ant-col-8 {
      max-width: 100% !important;
    }

    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }
  .form-password-input{
    flex-direction: row !important;
  }

  .ant-col-22 {
    max-width: 100% !important;
  }

  .InputLablDiv {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 5% !important;
    @media screen and (max-width: 767px) {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      margin-top: 10px;
    }
  }
// .Filterrall{
//   display: flex;
//   flex-direction: column;
//   gap: -15px;
// }

.FilterIp{
  display: flex;
  flex-direction: row !important;
  // justify-content: start;
  align-items: center;
  box-shadow: 0px 4px 10px 2px rgba(0, 0, 0, 0.3);
margin-top: 1rem;
border-radius: 4px;
width: 100%;
}

// .Filter .delete_number{
//   width: 10% !important;
// }
@media only screen and (max-width: 767px) {
  .Filter{
    display: flex;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    align-content: center !important;
  }
  
}
  .ant-form-item-label {
    text-align: left !important;
    width: 100% !important;
  }
  .css-bhp9pd-MuiPaper-root-MuiCard-root{
    box-shadow: none !important;
  }
  .AdresseIP{
width: 80% !important;
  }
  .LabelCard{
color : black !important; 
font-weight: bold !important;
  }
  .textFieldModal{
    margin-bottom: 3% !important;
    width : 98% !important ;
    margin-right : 2% !important
  }
  .closeButton{
    color: #1976D2 !important;
  border: 1px solid 	#1976D2 !important;
  margin-left : 1% !important;
  float : right !important;
  margin-right : 2% !important; 
  margin-top: 3% !important; 
  padding-left: 2% !important;
  padding-right: 2% !important;
  padding-top: 1% !important;
  padding-bottom: 1% !important;
  border-radius: 5% !important;

  }
.title{
  font-weight: bold !important;
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2% !important;
  
}
.highlightIcon{
  color : black !important
}
.dataTable {
  height: 100% !important;
  width: 100% !important;  
  margin-top : 1% !important; 
  border: none !important; 
  // margin-left: 2% !important ;
  margin-bottom: -20px !important;

}
.actionIp {
width: 100px !important;
}
.css-qg0cwk-MuiPaper-root {
  box-shadow: none !important;
}
.search{
 padding: 2px 4px !important;
  display: flex !important;
   align-items: center !important;
    width: 100% !important;
      box-shadow: none !important;
      margin-left: 2% !important;
      width: 95% !important;
      margin-right: 5% !important;
        background-color:#F0F0F0 !important;
        opacity: 30% !important;
       
}
.addbutton{
  background-color: #1890FF !important ;
  color: white !important ;
  display: flex !important;
  justify-content: flex-end !important;
  align-items: flex-end !important;
  margin-left: 45% !important;
}
.btnCancel{
  background-color: white !important ;
  color: #1976D2 !important ;
  border : 2px solid #1976D2 !important ;
  padding-left: 10% !important ;
  padding-right: 10% !important ;


}

.btnSubmit{
  background-color: #1890FF !important;
  color: white !important;
  padding-left: 10% !important ;
  padding-right: 10% !important ;
}
.div_btn_add_cancel{
  width: 280px !important;
}
.Breadcrumb{
  margin-left: 2% !important;
}
.search-bar-Ip-main{
  margin-top: 0;
}

.loading-container {
  height: 80vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.theme{
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-weight: 500;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}
.notFoundContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.actionIp{
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: space-between;
  div{
    font-size: 15px !important;
    .icon_action{
      cursor: pointer !important;

    }
  }
  @media screen and (max-width: 768px) {
    //width: 300px !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: space-between !important;
  }
}
.tableDatta{
  height: 100%;
}
.tableDattaHeader{
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  justify-content: center;
}
 


.delete_number{
  margin-top: 1rem !important;
}

.suppText{
  font-family: Roboto;
font-size: 14px;
font-weight: 400;
line-height: 22px;
letter-spacing: 0px;
text-align: left;
width: 220px;
margin-top: 1rem;
}
.messageDelete{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 0.8rem;
}
.DataTableIp{
  height: 100% !important;
white-space: nowrap !important;
}

.bordersIp fieldset {
  border: 1px solid rgb(224, 224, 224);
}

.generalinfosIp{
  color: rgba(0,0,0,.65);
font-size: 20px;
font-weight: 700;
margin-bottom: 15px;
margin-top: 15px;
}


input::-webkit-search-results-decoration {
  display: none;
}