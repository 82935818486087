

html {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
  
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.header {
  display: flex;
}

.Default_Page{
  position: absolute;
  left: 50%;
  top:50%;
  transform: translate(-50% , -50%);
}
.Default_Page span{
  font-size:80px;
  font-weight:800;
  text-align:center;
  font-family: 'Roboto', sans-serif;
}
.Default_Page h3{
  font-size:25px;
  text-align:center;
  font-family: 'Roboto', sans-serif;
  margin-top:-40px;
}
.Default_Page p{
  text-align:center;
  font-family: 'Roboto', sans-serif;
  font-size:12px;
}

.menu {
  .ant-menu-horizontal {
    & > .ant-menu-submenu {
      float: right;
    }
    border: none;
  }
  box-shadow: #e4ecef;
  position: relative;
  .ant-menu-submenu-title {
    width: 64px;
    height: 64px;
    text-align: center;
    padding-top: 8px;
  }
}
.ant-layout-sider::-webkit-scrollbar{
  width: 4px !important;
  height: 4px;
}
.ant-layout-sider::-webkit-scrollbar-track{
  background-color: #FFFFFF !important;
  z-index: 999;
  border: none !important;
  box-shadow: none !important;
}
.ant-layout-sider-dark::-webkit-scrollbar-track{
  background-color: #011529 !important;
  z-index: 999;
  border: none !important;
  box-shadow: none !important;
}
.ant-layout-sider::-webkit-scrollbar-thumb{
  background-color: rgba(0, 0, 0, 0.25);    /* color of the scroll thumb */
  border-radius: 20px;       /* roundness of the scroll thumb */
}
.ant-layout-sider-dark::-webkit-scrollbar-thumb{
  background-color: rgba(255, 255, 255, 0.25);    /* color of the scroll thumb */
  border-radius: 20px;       /* roundness of the scroll thumb */
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover svg{
  color: #1890ff !important;
}
@media only screen and (max-width: 768px) {
.css-piqts5-MuiInputBase-root {
    font-size: 10px !important;
    margin-left: 0px !important;
  }
}
@media only screen and (width: 280px) {
  .css-i4bv87-MuiSvgIcon-root {
    font-size: 10px !important;
  }
 
}
 .listModule {
   overflow: scroll !important;
 }

 .listModule::-webkit-scrollbar {
   height: 2px;
 }
.trigger {
  margin-left: 16px;
  margin-right: 16px;
  align-self: center;
  font-size: 16.25px !important;
}
.menu-toggle{
  float: left;
  width: 100%;
  align-self: center;
  display: flex;
  height: 100%;
}
.ant-menu-inline-collapsed{
  font-size: 0;
}
.logo-content{
  display: flex;
  flex-direction: row;
  height: 63px;
}
.svg{
  margin-right: 10px;
  font-size: 14px!important;
}
.switch {
  position: relative;
  display: inline-block;
  height: 25px;
  width: 75px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 27px;
  width: 47px;
}


input:checked + .slider {
  background-color: #b6b6b6;
  border-radius: 27px;
  height: 25px;
}

input:focus + .slider {
  box-shadow: 0 0 1px #b6b6b6;
}

.switch-mode{
  padding: 1.3em 0;
}
.ant-pagination-options{
  position: absolute;
  left: 0;
}
.row-event{
  height: 58px;
  background-color: rgba(0, 0, 0, 0.04);
  align-items: center;
  padding: 0 2em;
}
.text-event{
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  color: rgba(0, 0, 0, 0.85);
}
.col-search{
  width: 60%;
}
.input-search{
  height: 40px;
  padding: 0!important;
}
.row-action{
  justify-content: space-between;
  margin: 1em 0;
}
.calendar{
  width: 205px;
  height: 40px;
  border-radius: 2px;
  border: solid 1px rgba(0, 0, 0, 0.15);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.emp{
  justify-content: space-between;
  height: 58px;
  background-color: rgba(0, 0, 0, 0.04);
  align-items: center;
  padding: 0 1.5em;
}
.title{
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  color: rgba(0, 0, 0, 0.85);
  margin-left: 10px;
}
.label{
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: rgba(0, 0, 0, 0.85);
  margin-bottom: 0.5em;
}
.require{
  color: #ff4d4f;
  margin-left: 2px;
}
.input-form{
  display: flex;
  flex-direction: column;
}
.input-form-date{
  margin-right: 2em;
  display: flex;
  flex-direction: column;
}
.row-tabPane{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.date-picker{
  display: flex;
  flex-direction: row;
}
.bloc-form{
  width: 49%;
  border-radius: 2px;
  border: solid 1px rgba(0, 0, 0, 0.15);
  padding: 2em;
}
.text{
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: rgba(0, 0, 0, 0.65);
  margin-left: 10px;
}
.colTabPane{
  width: 50%;
  border-radius: 2px;
  border: solid 1px rgba(0, 0, 0, 0.15);
  padding: 2em;
}

.Sider {
  display: block;
}
@media only screen and (max-width: 768px) {
  .Sider {
    display: none;
  }
}
@media only screen and (max-width: 510px) {
  .header_container_div {
      display: flex !important;
      flex-direction: column !important;
      align-items: center !important;
      justify-content: center !important;
      gap: 5px !important;
      height: 80px !important;
  
    }
}


@media only screen and (max-width: 540px) {
.css-ypiqx9-MuiDialogContent-root{
overflow: hidden !important;
  padding-left: 9px !important;

}
.modal-confirm-content {
  width: auto !important;
}}

.MuiDialogActions-spacing{
  gap: 2rem;
  margin: 0 1rem;
}

@media only screen and (max-width: 440px) {
.MuiDialogTitle-root+.css-ypiqx9-MuiDialogContent-root {
    padding-top: 12px !important
  }
}


.drawer {
  display: none;
}
@media only screen and (max-width: 768px) {
  .drawer {
    display: block;
  }
}


input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
  display: none;
}

/* For newer versions of Edge (EdgeHTML 18+), you can also use the following: */
input[type="password"]::-webkit-text-security {
  display: none;
}

