.permission {
  height: calc(100vh - 110px);
  .inputFilter {
    height: 40px !important;
  }
  .card_permission {
    height: calc(100vh - 110px);
    display: flex;
    flex-direction: row;
margin-left: 15px !important;
    .ant-card-bordered {
      //height: 70vh;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        width: 4px !important;
        height: 4px;
      }
      &::-webkit-scrollbar-track {
        background-color: gray !important;
        z-index: 999;
        border: none !important;
        box-shadow: none !important;
      }
      &::-webkit-scrollbar-track {
        background-color: #f5f5f5 !important;
        z-index: 999;
        border: none !important;
        box-shadow: none !important;
      }
      &:first-child {
        width: 40%;
      }
      &:last-child {
        width: 60%;
      }
    }
    .ant-card-body {
      padding: 0 !important;
    }
    .moduleDiv {
      width: 100%;
      padding: 15px 20px;
      border-bottom: 1px solid RGB(217, 217, 217);
      font-size: 15px;
      font-weight: 400;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      &:hover {
        background-color: #f5f5f5 !important;
      }
      .iconArrow {
        font-size: 10px;
      }
    }
@media only screen and (max-width: 768px) {
  .moduleDiv {
font-size: 13px !important; 
width: auto !important;
 }
}



    .arrow {
      border: solid black;
      border-width: 0 3px 3px 0;
      display: inline-block;
      padding: 3px;
    }
    
    .right {
      transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
    }

    .down {
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
    }
    
    .permission_action {
      width: 100%;
      .actionDiv {
        margin: 0 auto;
        width: 95%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid RGB(217, 217, 217);
        padding: 15px;
        height: 60px;
        @media screen and (max-width:750px){
          height: 100%;
        }
        .check_actionDiv {
          margin-right: 15px;
        }
      }
    }



  @media only screen and (max-width: 768px) {
  .spanDiv {
      width: auto !important;
                                          }
                                        }

    .spanDiv {
      margin: 20px auto;
      width: 99%;
      background-color: RGB(245, 245, 245);
      padding: 10px 20px;
    }
    .divSelectAll {
      margin: 10px;
      width: 95%;
      padding: 0 12px;
      display: flex;
      flex-direction: row;
      align-items: center;
      @media only screen and (max-width: 768px){
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      .Checkbox {
        margin-right: 10px !important;
        font-size: 12px;
        font-weight: 500;
      }
      .activeDesactiveDiv {
        color: RGBA(24, 144, 255, 0.46);
        margin-bottom: 5px;
        .activ {
          font-size: 13px;
          margin-right: 5px;
          margin-left: 5px;
          border-bottom: 1px solid RGBA(24, 144, 255, 0.46);
          cursor: pointer;
        }
      }
    }
  }
}
.card{
  width: 65% !important;
  margin-left: 15px !important;
  overflow: auto !important;
  height: calc(100% - 25px);
  position: relative;
}
@media only screen and (max-width: 768px) {
  .card {
    width: auto !important;
  }
}
.card::-webkit-scrollbar {
  height: 2px;
}
.listModule{
  width: 35% !important;
  overflow-y: scroll !important;
  height: calc(100% - 25px);
  position: relative;
}

@media screen and (max-width: 768px) {
  .listModule {
    width: auto !important;
    padding-right: 10px !important;
  }
}



.rect_filter{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.2rem;
  width: 100%;
}
.search-bar{
  position: fixed;
  top: 0;
  left: 0;
}
.search-bar2{
  position: fixed;

}
.checkandinput{
  display: flex;
  flex-direction: row !important;
  align-items: center;
  box-shadow: 0px 4px 10px 2px rgba(0, 0, 0, 0.3);
border-radius: 4px;
width: 97.5%;
margin-inline: auto;
}
.check{
  margin-left: 1rem !important;
}

.BoldeSelectAll{
  font-weight: bold;
}